import Sprite = Phaser.GameObjects.Sprite;

class WordManager extends Phaser.GameObjects.Container {
    private indicatorScore: Sprite[];
    private indicatorWordsComplete: Sprite[];
    private pointRemaining: Phaser.GameObjects.Text;

    constructor(scene, x, y) {
        super(scene, x, y);

        this.indicatorScore = [];

        for (let i = 0; i < 5; i++) {
            if (i == 4) {
                this.indicatorScore[i] = scene.add.sprite(78, -28 - (10 * i), 'sprite', 'game/indicator-word-bonus.png');
            } else {
                this.indicatorScore[i] = scene.add.sprite(78, -24 - (10 * i), 'sprite', 'game/indicator-word.png');
            }
            this.add(this.indicatorScore[i]);
        }

        this.indicatorWordsComplete = [];
        for (let i = 0; i < 10; i++) {
            this.indicatorWordsComplete[i] = scene.add.sprite(-683 + (17.2 * i), -47, 'sprite', 'game/pot-lampje.png');
            this.add(this.indicatorWordsComplete[i]);
        }
        this.updateWordsComplete(0);

        this.pointRemaining = scene.add.text(148, -72, '50', {
            fontFamily: 'Londrina Solid',
            fontSize: '40px',
            color: '#ffffff',
            align: 'center',
        });
        this.add(this.pointRemaining);
    }

    updateScore(score: number): void {
        const index = this.scene.registry.values.indexWord;

        this.pointRemaining.text = score.toString();
        for (let i = 0; i < this.indicatorScore.length; i++) {
            if (i < index) {
                this.indicatorScore[i].alpha = 1;
            } else {
                this.indicatorScore[i].alpha = .2;
            }
        }
    }

    updateWordsComplete(count): void {
        for (let i = 0; i < this.indicatorWordsComplete.length; i++) {
            if (i < count) {
                this.indicatorWordsComplete[i].alpha = 1;
            } else {
                this.indicatorWordsComplete[i].alpha = .5;
            }
        }
    }
}

export default WordManager;
