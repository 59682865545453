import PotPlant, {PlantEvents} from './potPlant';


export default class PotGarden extends Phaser.GameObjects.Container {
    private plants: PotPlant[] = [];

    constructor(scene, x, y, nrLevels) {
        super(scene, x, y);

        this.initPlants(nrLevels);

        this.add(scene.add.sprite(-10, -157, 'sprite', 'game/tafel.png'));
        this.add(scene.add.sprite(495, -302, 'sprite', 'game/gieter.png'));
    }

    initPlants(nrLevels): void {
        for (let i = 1; i <= nrLevels; i++) {
            const plant = new PotPlant(this.scene, (195 * i - 586), -196, i);
            plant.on(PlantEvents.GrowStart, (x, y) => {
                this.emit(PlantEvents.GrowStart, x, y);
            });
            plant.on(PlantEvents.GrowComplete, () => {
                this.emit(PlantEvents.GrowComplete);
            });
            this.plants[i - 1] = plant;
            this.add(this.plants[i - 1]);
        }
    }

    wordComplete(level, step): void {
        this.plants[level - 1].grow(step);
    }

    plantActivate(level): void {
        this.plants[level - 1].activate();
    }

    blinkPlants(): void {
        for (let i = 1; i <= 5; i++) {
            this.plants[i - 1].blink(i - 1);
        }
    }

}
