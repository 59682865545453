/**
 * Game scene of the game.
 *
 * @author      Buro Meta
 * @copyright   2020 Buro Meta <https://www.burometa.nl>
 */

import Word from '../objects/word';
import {EnumDifficulty} from '../common/enums';
import {GameDefinitions} from '../types/gameDefinitions';
import PotGarden from '../objects/potGarden';
import WordManager from '../objects/wordManager';
import LevelUp from '../objects/levelUp';
import GameComplete from '../objects/gameComplete';
import ParticleCorrectWord from '../objects/particleCorrectWord';
import TypeTuinScene from './type-tuin-scene';
import {ScorePanelScene} from './score-panel-scene';
import ANY_KEY_DOWN = Phaser.Input.Keyboard.Events.ANY_KEY_DOWN;
import ANY_KEY_UP = Phaser.Input.Keyboard.Events.ANY_KEY_UP;
import {PlantEvents} from '../objects/potPlant';
import {GameEvents, LevelUpEvents} from '../common/events';
import TWEEN_COMPLETE = Phaser.Tweens.Events.TWEEN_COMPLETE;

export class GameScene extends TypeTuinScene {
    private gameDefinition: GameDefinitions | undefined;

    private currentLevel = 1;//0;
    public difficulty: EnumDifficulty = EnumDifficulty.easy;

    /*
     DATA
     */
    private currentLevelWordsCorrect = 0;
    private currLetter = 0;
    private nexLevelTransition = false;
    private currentWord;
    private scoreDifficultyBonus = [0, 10, 20];
    private potGarden: PotGarden | undefined;
    private wordManager: WordManager | undefined;
    private wordLayer: Phaser.GameObjects.Container | undefined;
    private levelUp: LevelUp | undefined;
    private words: string[] = [];
    private particleCorrectWord!: ParticleCorrectWord;
    private activeKeys = new Set();

    constructor() {
        super({key: 'GameScene'});
    }

    // region Phaser methods

    preload(): void {
        this.setCurrentLevel(this.registry.get('level') ?? this.currentLevel);
        this.gameDefinition = this.cache.json.get('gameDefinition') as GameDefinitions;
        this.difficulty = parseInt(localStorage.getItem('difficulty') ?? '0');
    }

    create(): void {
        super.create();
        /*
         VISUALS::GENERAL
         */
        this.make.sprite({x: this.game.canvas.width/2-12, y: this.game.canvas.height/2, key: 'sprite', frame: 'game/bg.png'}, true);

        this.potGarden = new PotGarden(this, (this.sys.canvas.width / 2) - 20, this.sys.canvas.height + 17, this.gameDefinition?.levels.length);
        this.potGarden.on(PlantEvents.GrowStart, (x: number, y: number) => {
            this.growStart(x, y);
        });
        this.potGarden.on(PlantEvents.GrowComplete, () => {
            this.growComplete();
        });
        this.potGarden.plantActivate(this.currentLevel);
        this.add.existing(this.potGarden);

        this.wordManager = new WordManager(this, (this.sys.canvas.width / 2) + 230, this.sys.canvas.height - 62);
        this.wordManager.y -= 35;
        this.add.existing(this.wordManager);

        this.wordLayer = this.add.container(this.sys.canvas.width / 2 - 70, this.sys.canvas.height - 96);
        this.add.existing(this.wordLayer);

        this.particleCorrectWord = new ParticleCorrectWord(this);
        this.add.existing(this.particleCorrectWord);

        this.levelUp = new LevelUp(this);
        this.levelUp.y = (this.sys.canvas.height / 2) - 50;
        this.levelUp.x = this.sys.canvas.width / 2;
        this.add.existing(this.levelUp);

        /*
         INIT
         */
        // todo 4000
        this.time.addEvent({
            delay: 500, callback: () => {
                this.initGame();

                // enable for testing the plants
                // this.unitTest();
            },
        });
    }

    // region unit test
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    async unitTest() {
        for (let lvl = 1; lvl <= 5; lvl++) {
            for (let step = 1; step <= 10; step++) {
                await this.sleep(500);
                this.potGarden?.wordComplete(lvl, step);
            }
        }
    }
    // endregion

    update(): void {
    }

    // endregion

    // region custom methods

    growStart(x, y) {
        this.particleCorrectWord.fire(x,y, this.currentLevel);
    }

    growComplete() {
        this.destroyWord();
        if (this.currentLevelWordsCorrect == this.wordsToComplete()) {
            this.nextLevel();
        }
    }

    initGame(): void {
        this.initWordsLevel();
        this.makeWord();
    }

    initWordsLevel(): void {
        if (this.gameDefinition !== undefined) {
            this.words = this.gameDefinition.levels[this.currentLevel-1].words[this.difficulty];
        }
    }

    wordsToComplete(): number | undefined {
        if (this.gameDefinition !== undefined) {
            return  this.gameDefinition.levels[this.currentLevel-1].wordsToComplete;
        }
    }

    setCurrentLevel(level: number): void {
        this.currentLevel = level;
        this.game.registry.values.level = this.currentLevel;
        this.events.emit(GameEvents.levelChange);
    }

    nextLevel(): void {
        if ((this.currentLevel) === this.gameDefinition?.levels.length) {
            this.nexLevelTransition = true;
            this.completeGame();
        } else {
            this.setCurrentLevel(this.currentLevel + 1);
            this.initWordsLevel();
            this.potGarden?.plantActivate(this.currentLevel);
            this.nexLevelTransition = true;
            this.audioManager.levelUpdate(this.currentLevel);
            this.levelUp?.show();
            this.levelUp?.on(LevelUpEvents.complete, () => {
                this.checkLevelTransitionComplete();
            });
            // this.game.controlPanel.updateLevel(this.currentLevel);
            this.currentLevelWordsCorrect = 0;
            this.wordManager?.updateWordsComplete(this.currentLevelWordsCorrect);
        }
    }

    makeWord(): void {
        const index = Math.floor(Math.random() * this.words.length);
        const word = this.words[index];
        this.words.splice(index, 1);
        this.currLetter = 0;
        this.resetScoreIndexWord();

        this.currentWord = new Word(this, 50, -52, word);
        this.currentWord.alpha = 0;

        //  Capture all key presses
        this.input.keyboard.on(ANY_KEY_DOWN, (char) => {
            if (!this.activeKeys.has(char.code)) {
                this.activeKeys.add(char.code);
                this.keyPress(char);
            }
        });
        this.input.keyboard.on(ANY_KEY_UP, (char) => {
            this.activeKeys.delete(char.code);
        });

        this.wordLayer?.add(this.currentWord);
        this.tweens.add({
            targets: this.currentWord,
            alpha: 1,
            duration: 200,
            ease: 'Linear',
            autoStart: true,
            delay: 300,
        });
    }

    decreaseScoreIndexWord(): void {
        this.registry.values.indexWord--;
        this.wordManager?.updateScore(this.calcScore());
    }

    resetScoreIndexWord(): void {
        this.registry.values.indexWord = 5;
        this.wordManager?.updateScore(this.calcScore());
    }

    calcScore(): number {
        return (this.registry.values.indexWord * 10) + this.scoreDifficultyBonus[this.difficulty];
    }

    keyPress(char): void {
        this.audioManager.playFX('tick',.8);
        if (char.key === this.currentWord.text[this.currLetter]) {
            this.currLetter++;
            this.currentWord.updateColor(this.currLetter);
            if (this.currLetter == this.currentWord.text.length) {
                this.wordCorrect();
            }
        } else {
            this.wrongLetter();
        }
    }

    incrementScore(): void {
        // 70-10 punten afhankelijk van de voortgang, level en moeilijkheidsgraad
        const score = this.calcScore();
        this.game.registry.values.score += score;
        this.events.emit(GameEvents.scoreChange);
    }

    wordCorrect(): void {
        this.endkeyboardCallbacks();
        this.incrementScore();
        this.currentLevelWordsCorrect++;
        this.currentWord.onCorrect();
        this.audioManager.playFX('wordok-'+(this.currentLevel) );
        // grows the plant, callback here when finished
        this.potGarden?.wordComplete(this.currentLevel, this.currentLevelWordsCorrect);
        this.wordManager?.updateWordsComplete(this.currentLevelWordsCorrect);
    }

    wrongLetter(): void {
        this.audioManager.playFX('wordwrong');
        this.currentWord.onWrong();
        if (this.registry.values.indexWord > 1) {
            this.decreaseScoreIndexWord();
        }
    }

    destroyWord(): void {
        this.endkeyboardCallbacks();
        const fadeOutWord = this.tweens.add({
            targets: this.currentWord,
            alpha: 0,
            duration: 200,
            ease: 'Linear',
            autoStart: true,
            delay: 300,
        });
        fadeOutWord.on(TWEEN_COMPLETE, () => {
            this.destroyWordComplete()
        });
    }

    destroyWordComplete(): void {
        this.currentWord.destroy();
        const wordCount = this.wordsToComplete();
        if (this.gameDefinition && (this.currentLevel) <= this.gameDefinition.levels.length
            && (wordCount === undefined || this.currentLevelWordsCorrect < wordCount)) {
            this.makeWord();
        }
    }

    checkLevelTransitionComplete(): void {
        this.nexLevelTransition = false;
    }

    completeGame(): void {
        const scoreDisplay = (this.scene.get('ScorePanelScene') as ScorePanelScene).scoreDisplay;
        scoreDisplay.setTotalScore();
        scoreDisplay.displayFinalScore();

        const gameComplete = new GameComplete(this);
        this.add.existing(gameComplete);
        this.potGarden?.blinkPlants();
        this.endGameRemove();

        this.audioManager.gameComplete();
    }

    endGameRemove(): void {
        this.currentWord?.destroy();
        this.endkeyboardCallbacks();
    }

    endkeyboardCallbacks(): void {
        this.input.keyboard.off('keydown');
    }

    // endregion
}
