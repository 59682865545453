import Sprite = Phaser.GameObjects.Sprite;
import Scene = Phaser.Scene;
import GameObject = Phaser.GameObjects.GameObject;
import TWEEN_COMPLETE = Phaser.Tweens.Events.TWEEN_COMPLETE;

export const enum PlantEvents {
    GrowStart = 'PLANT_GROW_START',
    GrowComplete = 'PLANT_GROW_COMPLETE',
}

const plantOffsets = [
    {x: 0, y: 0},
    {x: 0, y: 6},
    {x: -10, y: -10},
    {x: 10, y: 0},
    {x: 0, y: 0},
]

export default class PotPlant extends Phaser.GameObjects.Container {
    private light: Sprite;
    private level: number;
    private plant: SpineGameObject;

    constructor(scene: Scene, x, y, level) {
        super(scene, x, y);

        this.level = level;

        this.plant = scene.add.spine(8 + plantOffsets[this.level-1].x,-136 + plantOffsets[this.level-1].y, `plant${this.level}`, 'animation', true);
        this.plant.setSkinByName('default');
        this.add(this.plant as unknown as GameObject);

        for(let i = 1; i <= 10; i++) {
            const slot = this.plant.findSlot(`deel${i}`);
            slot.bone.scaleX = 0;
            slot.bone.scaleY = 0;
        }

        this.add(scene.add.sprite(0, 0, 'sprite', 'game/pot.png').setOrigin(0.5, 1));

        this.light = scene.add.sprite(9, -68, 'sprite', 'game/pot-lampje.png');
        this.light.alpha = 0;
        this.add(this.light);
    }

    /**
     * Grow plant part by part
      */
    grow(step: number): void {
        const slot = this.plant.findSlot(`deel${step}`);
        this.emit(PlantEvents.GrowStart, slot.bone.worldX, this.scene.game.canvas.height- this.height- slot.bone.worldY);
        const growPlant = this.scene.tweens.add({
            targets: slot.bone,
            scaleX: 1,
            scaleY: 1,

            duration: 1500,
            ease: 'Back.Out',
            autoStart: true,
        });
        growPlant.on(TWEEN_COMPLETE, () => {
            this.growComplete();
        });
    }

    /**
     * Blink (for game completed)
     *
     * @param index
     */
    blink(index: number): void {
        const slot = this.plant.findSlot('deel10');
        let scale = .5;
        if (index == 1 ) {
          scale = .9; // croco plant
        }

        this.scene.tweens.add({
            targets: slot.bone,
            scaleX: scale,
            scaleY: scale,

            duration: 2500,
            ease: 'Linear',
            autoStart: true,
            delay: index*100,
            repeat: -1,
            yoyo: true,
        });
    }

    // dispatch after grow
    growComplete(): void {
        this.emit(PlantEvents.GrowComplete, this);
    }

    // dispatch after grow
    activate(): void {
        this.light.alpha = 1;
    }
}
