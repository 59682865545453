import Scene = Phaser.Scene;
import TextStyle = Phaser.GameObjects.TextStyle;

export default class Word extends Phaser.GameObjects.Container {
    public scoreValue = 0;
    public text: string;

    private scoreValueBase = 0;
    private correctWord: Phaser.GameObjects.Text;
    private characterCrop: number[];

    constructor(scene: Scene, x = 0, y = 0, word: string) {
        super(scene, x, y);
        const style = {
            fontFamily: 'Londrina Solid',
            fontSize: '50px',
            color: '#382003',
            align: 'center',
            stroke: '#ffffff',
            strokeThickness: 3,
        } as TextStyle;

        this.add(scene.add.text(0, 0, word, style).setOrigin(0.5, 0.5));
        this.correctWord = scene.add.text(0, 0, word, Object.assign({}, style, {color: '#2bb62e'}))
        this.correctWord.setOrigin(0.5, 0.5);
        this.correctWord.setCrop(0, 0, 0, this.correctWord.height);
        this.add(this.correctWord);

        this.characterCrop = this.getCharacterCrop(word, style);
        this.text = this.correctWord.text;

        // set as props
        this.scoreValueBase = 10 * word.length;
        this.scoreValue = this.scoreValueBase;
    }

    onCorrect(): void {
        this.scene.tweens.add({
            targets: this,
            scale: 1.1,
            duration: 200,
            ease: 'Linear',//'Elastic.In',
            autoStart: true,
            delay: 0,
            yoyo: true,
        });
    }

    onWrong(): void {
        this.rotation = 0;
        if (!this.scene.tweens.isTweening(this)) {
            this.scene.tweens.add({
                targets: this,
                rotation: .05,
                duration: 100,
                ease: 'Linear',
                autoStart: true,
                // delay: 100,
                yoyo: true,
            });
        }
    }

    /**
     * Unveils the correct part of the word by setting a cropping to the character position.
     * Phaser 2 had the possibility to set the color of single characters in a text object, but Phaser 3
     * does not have this option.
     *
     * @param pos
     */
    updateColor(pos: number): void {
        this.correctWord.setCrop(0, 0, this.characterCrop[pos - 1], this.correctWord.height);
    }

    /**
     * In this helper function the width of each character of the word is calculated
     * and placed in the characterCrop array.
     * The array contains cumulative values, so it can be easily used to set the cropping
     * in the updateColor function.
     *
     * @param word
     * @param style
     */
    private getCharacterCrop(word: string, style: TextStyle): number[] {
        const chWidths: number[] = [];
        const txt = this.scene.make.text({
            style: style,
        }, false)

        let width = 0;// txt.style.baselineX;
        let txtPart = '';
        for (const character of word) {
            txtPart += character;
            txt.setText(txtPart);
            txt.updateText();
            width = txt.width - txt.style.baselineX * 2
            chWidths.push(width);
        }
        return chWidths;
    }
}
