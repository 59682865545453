import ParticleEmitterConfig = Phaser.Types.GameObjects.Particles.ParticleEmitterConfig;
// import {BlendModes} from 'phaser';

export default class ParticleCorrectWord extends Phaser.GameObjects.Group {

    //initialization code in the constructor
    private duration = 500;
    private emitterConfig: ParticleEmitterConfig;
    private particles: Phaser.GameObjects.Particles.ParticleEmitterManager;
    private emitter: Phaser.GameObjects.Particles.ParticleEmitter;
    private currentLevel = 1;

    constructor(scene) {
        super(scene);
        this.emitterConfig = {
            delay: {min: 0, max: 200},
            lifespan: this.duration,
            scale: {start: 0.5, end: 1, ease: 'Expo.easeOut'},
            alpha: {start: 0.8, end: 0.3, ease: 'Expo.easeIn'},
            quantity: 20,
            frequency: 800,
            speed: [100, 200],
            rotate: {start: -90, end: 90},
            angle: {min: 180, max: 360},
            gravityY: -200,
            on: false,

            blendMode: 'SCREEN',
        } as ParticleEmitterConfig;

        this.particles = this.scene.add.particles('sprite', 'game/items/flare_point.png');
        this.add(this.particles);
        this.emitter = this.particles.createEmitter(this.emitterConfig);
    }

    fire(xPos, yPos, level: number) {
        this.currentLevel = level;
        this.emitter.setPosition(xPos, yPos);
        this.emitter.start();

        setTimeout(() => {
            this.emitter.on = false;
        }, this.duration);
    }
}
