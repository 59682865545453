import {Button} from './button';
import {ScorePanelScene} from '../scenes/score-panel-scene';
import TWEEN_COMPLETE = Phaser.Tweens.Events.TWEEN_COMPLETE;

export default class ScoreDisplay extends Phaser.GameObjects.Container {
    private scoreList: number[];
    private ranking: number;
    private open = false;
    private buttonOpen: Button;
    private textGroup: Phaser.GameObjects.Group;
    private originalY!: number;

    public newHighScore: boolean;
    public newScore: boolean;
    public scene!: ScorePanelScene;

    constructor(scene: ScorePanelScene, x, y) {
        super(scene, x, y);

        this.add(scene.add.sprite(0, 0, 'sprite', 'score/bord.png').setOrigin(0,0));

        this.scoreList = [];
        const lsScore = localStorage.getItem('score');
        if (lsScore !== null) {
            this.scoreList = JSON.parse(lsScore);
        }

        this.buttonOpen = new Button(scene, 120, 43, () => {
            this.onToggle();
        }, 'sprite', 'score/btn-score-hover.png', 'score/btn-score.png', 'score/btn-score-hover.png');
        this.add(scene.add.existing(this.buttonOpen));

        this.textGroup = new Phaser.GameObjects.Group(scene);

        this.saveScore();
        this.ranking = -1;
        this.newHighScore = false;
        this.newScore = false;

        this.makeList();
    }

    hide() {
        this.buttonOpen.input.enabled = false;
        this.scene.tweens.add({targets: this, y: 1000, duration: 300, ease: 'Cubic.Out', autoStart: true});
    }

    displayFinalScore() {
        // this.x = 75;
        this.scene.tweens.add({targets: this, y: 348, duration: 300, ease: 'Cubic.Out', autoStart: true, delay: 400});
    }

    makeList() {
        this.textGroup.clear(true);
        let txtColor;
        let txt;
        for (let i = 0; i < 3; i++) {
            if (i == this.ranking) {
                txtColor = '#56c50a';
            } else {
                txtColor = '#e79609';
            }
            if (this.scoreList[i]) {
                txt = this.scoreList[i];
            } else {
                txt = '-';
            }
            const text = this.scene.make.text({x: 75, y: 118 + i * 39, text: txt, style: {font: '26px Londrina Solid', fill: txtColor}}, true);
            this.add(text);
            this.textGroup.add(text);
        }
    }

    deactivateToggle() {
        this.buttonOpen.input.enabled = false;
    }

    activateToggle() {
        this.buttonOpen.input.enabled = true;
    }

    onToggle() {
        this.deactivateToggle();

        this.scene.audioManager.playFX('movescore');
        if (!this.open) {
            this.originalY = this.y;
            const tweenToggle = this.scene.tweens.add({targets: this, y: 348, duration: 300, ease: 'Cubic.Out', autoStart: true});
            tweenToggle.on(TWEEN_COMPLETE, () => {
                this.activateToggle();
            });
        } else {
            const tweenToggle = this.scene.tweens.add({targets: this, y: this.originalY, duration: 300, ease: 'Cubic.Out', autoStart: true});
            tweenToggle.on(TWEEN_COMPLETE, () => {
                this.activateToggle();
            });
        }
        this.open = !this.open;
    }

    /**
     insert new score (if in top 3) and return the index
     */
    setTotalScore() {

        // todo it would be better to put the results in registry, so other scenes could load
        //  from registry.

        // get score from registry
        const score = this.scene.registry.get('score');

        for (let i = 0; i < 3; i++) {
            // equals
            if (score == this.scoreList[i]) {
                this.ranking = i;
                break;
                // empty or higher!!!
            } else if (!this.scoreList[i] || score > this.scoreList[i]) {
                this.ranking = i;
                this.newScore = true;
                if (i == 0) {
                    this.newHighScore = true;
                }
                this.scoreList.splice(i, 0, score);
                break;
            }
        }
        this.scoreList = this.scoreList.slice(0, 3);
        this.saveScore();
        this.makeList();
    }

    /**
     * save score list in local storage
      */
    saveScore() {
        this.scoreList.sort((a, b) => {
            return b-a;
        });
        localStorage.setItem('score', JSON.stringify(this.scoreList));
    }

}
