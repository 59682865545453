import {ScorePanelScene} from '../scenes/score-panel-scene';
import {GameScene} from '../scenes/game-scene';
import TWEEN_COMPLETE = Phaser.Tweens.Events.TWEEN_COMPLETE;
import TWEEN_START = Phaser.Tweens.Events.TWEEN_START;
import POINTER_DOWN = Phaser.Input.Events.POINTER_DOWN;

export default class GameComplete extends Phaser.GameObjects.Sprite {
    private mooi: Phaser.GameObjects.Sprite | undefined;
    private opnieuw: Phaser.GameObjects.Sprite | undefined;
    private stoppen: Phaser.GameObjects.Sprite | undefined;
    public scene!: GameScene;

    constructor(scene: GameScene, x?, y?) {
        super(scene, x, y, 'sprite', 'game/end/text-end-goed.png');

        this.setOrigin(0, 0.5);
        this.x = 170;
        this.y = (this.scene.sys.canvas.height) - 144;// + 228;
        this.scale = 0;

        const tween = this.scene.tweens.add({targets: this, scale: 1, duration: 500, ease: 'Bounce.Out', autoStart: true, delay: 500});
        tween.on(TWEEN_COMPLETE, () => {
            this.showComplete();
        });
        tween.on(TWEEN_START, () => {
            this.scene.audioManager.playFX('gamecomplete');
        });
    }

    showComplete(): void {
        const scoreDisplay = (this.scene.scene.get('ScorePanelScene') as ScorePanelScene).scoreDisplay;
        const x = this.scene.sys.canvas.width / 2 + 250;
        const y = this.scene.sys.canvas.height - 144;
        if (scoreDisplay.newHighScore) {
            this.mooi = this.scene.make.sprite({x: x, y: y, key: 'sprite', frame: 'game/end/text-end-high.png'}, true);
        } else if (scoreDisplay.newScore) {
            this.mooi = this.scene.make.sprite({x: x, y: y, key: 'sprite', frame: 'game/end/text-end-mooi.png'}, true);
        } else {
            this.mooi = this.scene.make.sprite({x: x, y: y, key: 'sprite', frame: 'game/end/text-end-leuk.png'}, true);
        }
        this.mooi.scale = 0;
        const tween = this.scene.tweens.add({targets: this.mooi, scale: 1, duration: 500, ease: 'Bounce.Out', autoStart: true, delay: 900});
        tween.on(TWEEN_START, () => {
            if (scoreDisplay.newHighScore) {
                 this.scene.audioManager.playFX('highscore');
            } else if (scoreDisplay.newScore) {
                this.scene.audioManager.playFX('highscore',.5);
            } else {
                this.scene.audioManager.playFX('highscore',.3);
            }
        });
        tween.on(TWEEN_COMPLETE, () => {
            this.showComplete2();
        });
    }

    showComplete2(): void {
        const x = this.scene.sys.canvas.width / 2;
        const y = this.scene.sys.canvas.height / 2;

        this.opnieuw = this.scene.make.sprite({x: x, y: y - 50, key: 'sprite', frame: 'game/end/btn-opnieuw-hover.png'}, true);
        this.opnieuw.setInteractive({ useHandCursor: true });
        this.opnieuw.on(POINTER_DOWN, () => {
            this.actionOnClickOpnieuw();
        });

        this.stoppen = this.scene.make.sprite({x: x, y: y, key: 'sprite', frame: 'game/end/btn-stoppen.png'}, true);
        this.stoppen.setInteractive({ useHandCursor: true });
        this.stoppen.on(POINTER_DOWN, () => {
            this.actionOnClickStop();
        });

        this.opnieuw.scale = 0;
        this.stoppen.scale = 0;

        this.scene.tweens.add({targets: this.opnieuw, scale: 1, duration: 300, ease: 'Bounce.Out', autoStart: true, delay: 1200});
        this.scene.tweens.add({targets: this.stoppen, scale: 1, duration: 300, ease: 'Bounce.Out', autoStart: true, delay: 1500});
    }

    actionOnClickOpnieuw(): void {
        window.location.reload();
    }

    actionOnClickStop(): void {
        const href = window.document.getElementById('url-origin')?.getAttribute('href');
        window.location.href = href ?? '';
    }
}
