/**
 * Boot scene of the game.
 *
 * @author      Buro Meta
 * @copyright   2020 Buro Meta <https://www.burometa.nl>
 */

import FontFaceObserver from 'fontfaceobserver';
import TypeTuinScene from './type-tuin-scene';
import COMPLETE = Phaser.Loader.Events.COMPLETE;
import preloaderImg from './../assets/graphics/preloader.gif';

export class BootScene extends TypeTuinScene {
    private loadingBar: Phaser.GameObjects.Image | undefined;
    private progressBar: Phaser.GameObjects.Graphics | undefined;

    // region Phaser methods
    constructor() {
        super({
            key: 'BootScene',
            pack: {
                files: [
                    {type: 'image', key: 'preloader', url: preloaderImg},
                ],
            },
        });
    }

    preload(): void {
        this.createProgress();

        new FontFaceObserver('Londrina Solid').load();

        this.load.json('gameDefinition', './dist/assets/data/gameDefinition.json');
        this.load.path = './dist/assets/';
        this.load.atlas('sprite', 'graphics/sprite-0.png', 'graphics/sprite.json');

        // todo use the sprite atlas from above as atlas for spine (using the LoaderPlugin class???)

        // https://stackoverflow.com/questions/57776815/loading-a-spine-atlas-png-and-json-from-base64-in-phaser
        // https://phaser.discourse.group/t/how-to-use-spine-models-which-share-an-atlas/4657 (no good answer)
        // https://github.com/azerion/phaser-spine/issues/45
        // pixi variant: https://github.com/pixijs/pixi-spine/blob/master/examples/preloaded_json.md


        // this.load.path = './dist/assets/graphics/';
        // this.load.spine('plant1', 'spines/plant1.json', 'sprite.json');


        this.load.path = './dist/assets/graphics/spines/';
        for (let i = 1; i <= 5; i++) {
            this.load.spine(`plant${i}`, `plant${i}.json`, `plant${i}.atlas`);
        }

        this.load.path = './dist/assets/audio/';
        this.load.audio('loop-intro', ['loop-01-intro.mp3', 'loop-01-intro.ogg']);
        this.load.audio('loop-game', ['loop-02-game.mp3', 'loop-02-game.ogg']);
        this.load.audio('loop-einde', ['loop-03-einde.mp3', 'loop-03-einde.ogg']);
        this.load.audioSprite('sfx', 'audiosprite.json', ['audiosprite.ogg', 'audiosprite.mp3'])
    }

    createProgress() {
        // load preloader image
        this.loadingBar = this.add.image(this.cameras.main.width / 2, this.cameras.main.height / 2 - 10, 'preloader');
        this.progressBar = this.make.graphics({x: this.loadingBar.x, y: this.loadingBar.y}, false);
        this.loadingBar.mask = this.progressBar.createGeometryMask();

        // pass value to change the loading bar fill
        this.load.on(
            'progress',
            (value) => {
                this.progressBar?.clear();
                this.progressBar?.fillStyle(0xffffff, 1);
                this.progressBar?.fillRect(
                    -(this.loadingBar?.displayWidth ?? 0)/2,
                    -(this.loadingBar?.displayHeight ?? 0)/2,
                    (this.loadingBar?.displayWidth ?? 0) * value,
                    this.loadingBar?.displayHeight ?? 0,
                );
            },
            this
        );

        // delete bar graphics, when loading complete
        this.load.on(COMPLETE, () => {
            this.tweens.add({targets: this.loadingBar, alpha: 0, duration: 200, ease: 'linear',
                onComplete: () => {
                    this.progressBar?.destroy();
                    this.loadingBar?.destroy();
                    this.scene.start('MenuScene');
                    this.scene.start('ScorePanelScene');
                },
            });
        }, this);
    }

    create(): void {
        super.create();
        this.audioManager.start();
    }

    update(): void {
    }

    // endregion












}
